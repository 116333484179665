import {
	ClientEntityType,
	JobEntityType,
	JobItemEntityType,
	PatientEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "../../pdf-templates/document-template.types";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "../../store/Central/selectors";
import {
	GsStatusEnum,
	KvStatusEnum,
	LsStatusEnum,
} from "../actions/actions-hooks-types";
import { calculateJobTotalsLegacy } from "../../lib/utils/calculate";
import { useCentralStore } from "../../store/Central";
import { useJobStore } from "../../store/Jobs";

import {
	documentPropsFor,
	useExportActions,
} from "../actions/useExportActions";
import { useState } from "react";
import { getUnderscoredString } from "../../lib/utils/utils-functions";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";
import { JobStatusEnum } from "@/lib/types/job";
import { useJobActions } from "../actions/useJobActions";
import { usePatientStore } from "../../store/Patient";
import { getBankAccount } from "../actions/get-bank-account/get-bank-account";
import { useAccountingStore } from "../../store/Accounting";
import { InvoiceType } from "../../types/database-enums/invoices-enums";
import { JobDocumentWithFiles } from "../../store/Jobs/job-documents.store";
import { LazyPatient } from "../../store/Patient/patients.store";

export const documentIsInvoice = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === PdfTemplateType.RECHNUNG ||
		type === PdfTemplateType.BARZAHLUNGSQUITTUNG ||
		type === PdfTemplateType.KARTENZAHLUNGSQUITTUNG ||
		type === PdfTemplateType.AKONTORECHNUNG
	);
};

export const documentIsDocument = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === PdfTemplateType.KOSTENVORANSCHLAG ||
		type === PdfTemplateType.LIEFERSCHEIN ||
		type === PdfTemplateType.AUFTRAGSBLATT ||
		type === PdfTemplateType.GUTSCHRIFT
	);
};

export const documentIsXml = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === XmlTemplateType.KOSTENVORANSCHLAG_XML ||
		type === XmlTemplateType.LIEFERSCHEIN_XML
	);
};

export const getFileNameForDocument = ({
	templateType,
	jobDocument,
	job,
	client,
	patientsLookup,
}: {
	templateType: PdfTemplateType;
	jobDocument: JobDocumentWithFiles;
	job: JobEntityType;
	client: ClientEntityType;
	patientsLookup: Record<string, LazyPatient>;
}) => {
	let fileName = jobDocument.title
		? getUnderscoredString(jobDocument.title)
		: "Kostenvoranschlag";

	if (templateType === PdfTemplateType.LIEFERSCHEIN) {
		fileName = jobDocument.title
			? getUnderscoredString(jobDocument.title)
			: "Lieferschein";
	} else if (templateType === PdfTemplateType.AUFTRAGSBLATT) {
		fileName = "Auftragsblatt";
	} else if (templateType === PdfTemplateType.GUTSCHRIFT) {
		fileName = jobDocument.title
			? getUnderscoredString(jobDocument.title)
			: "Gutschrift";
	}

	// prefix job code
	if (job?.code) {
		fileName = `${job.code}_${fileName}`;
	}

	// prefix patient name if needed
	if (client.is_pt_name_in_doc_name) {
		const patientName =
			job.patient_id && patientsLookup[job.patient_id]
				? `${patientsLookup[job.patient_id]?.last_name}${patientsLookup[
						job.patient_id
					]?.first_name?.slice(0, 1)}`
				: "";
		fileName = `${patientName}_${fileName}`;
	}

	return fileName;
};
